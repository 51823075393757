// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
@tailwind base;
@tailwind components;
@tailwind utilities;
 */

svg {
  display: inline-block;
}

.mc-field-group input:not([type="submit"]) {
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 10px 8px;
  background: transparent;
  border: 1px solid rgba(0, 92, 170, 0.6);
  border-radius: 3px;
  margin-bottom: 20px;
  transition: color 300ms ease, border 300ms ease;
  z-index: 1;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.75;
  color: #005caa;
}
`, "",{"version":3,"sources":["webpack://./styles/app.css"],"names":[],"mappings":"AAAA;;;;EAIE;;AAEF;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,iBAAiB;EACjB,uBAAuB;EACvB,uCAAuC;EACvC,kBAAkB;EAClB,mBAAmB;EACnB,+CAA+C;EAC/C,UAAU;EACV,oCAAoC;EACpC,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["/*\n@tailwind base;\n@tailwind components;\n@tailwind utilities;\n */\n\nsvg {\n  display: inline-block;\n}\n\n.mc-field-group input:not([type=\"submit\"]) {\n  position: relative;\n  display: inline-block;\n  width: 100%;\n  padding: 10px 8px;\n  background: transparent;\n  border: 1px solid rgba(0, 92, 170, 0.6);\n  border-radius: 3px;\n  margin-bottom: 20px;\n  transition: color 300ms ease, border 300ms ease;\n  z-index: 1;\n  font-family: \"Open Sans\", sans-serif;\n  font-size: 16px;\n  line-height: 1.75;\n  color: #005caa;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
